/* line 101, /Applications/MAMP/htdocs/naim/color-lib/160 lagoon Logistics/scss/_home.scss */
.single_brand {
  text-align: center;
  padding: 20px 15px;
  transition: all 300ms linear 0s;
  border: 1px solid #e4e7e9;
}

.topleft{
  color:#fff; font-size: 14px; float: left; padding-top:0;
}



.topright{
  color:#fff;font-size: 14px;  float: right;
}

@media (max-width: 991px) {

  .single_brand {
    border: 0px;
    margin-bottom: 0px;
  }
}

@media (max-width: 768px) {
.navbar-brand {
  width:100px;
  }
}

/* line 110, /Applications/MAMP/htdocs/naim/color-lib/160 lagoon Logistics/scss/_home.scss */
.single_brand:nth-child(1), .single_brand:nth-child(2), .single_brand:nth-child(3) {
  border-top: 0px;
}

/* line 115, /Applications/MAMP/htdocs/naim/color-lib/160 lagoon Logistics/scss/_home.scss */
.single_brand:nth-child(4), .single_brand:nth-child(5), .single_brand:nth-child(6) {
  border-bottom: 0px;
}

/* line 120, /Applications/MAMP/htdocs/naim/color-lib/160 lagoon Logistics/scss/_home.scss */
.single_brand:nth-child(1), .single_brand:nth-child(4) {
  border-left: 0px;
}

/* line 124, /Applications/MAMP/htdocs/naim/color-lib/160 lagoon Logistics/scss/_home.scss */
.single_brand:nth-child(3), .single_brand:nth-child(6) {
  border-right: 0px;
}

/* line 128, /Applications/MAMP/htdocs/naim/color-lib/160 lagoon Logistics/scss/_home.scss */
.single_brand img {
 
  transition: all 300ms linear 0s;
}

/* line 133, /Applications/MAMP/htdocs/naim/color-lib/160 lagoon Logistics/scss/_home.scss */
.single_brand:hover {
  background: #ffffff;
  box-shadow: 0px 10px 40px -14px #e72727;
}

/* line 136, /Applications/MAMP/htdocs/naim/color-lib/160 lagoon Logistics/scss/_home.scss */
.single_brand:hover img {
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
}