
h5.heading{
	font-size: 20px; font-weight: bold; margin-bottom: 10px;
}
	
.bg-light {
    background-color: #eceff1!important;
}
.rounded {
	border-radius:.25rem!important;
	border:solid 1px rgb(182, 180, 180);
	padding: 10px;
}

.help-block{
	color:red;
	font-size:12px;
	}
	
.topmargin{
	margin-top: 10px;
}
.top-menu{
	background: #383939;
	/*position: fixed;*/
	z-index: 999;
	width: 100%;
	left: 0px;
	height:30px;
 }
 .boxborder img {
	border: 0;
	height: auto;
	max-width: 100%;
	width: auto;
 }
 .footerLinks li{ 	
    position: relative;
    margin: 0;
    padding: 0;
    color:#fff;
    
 }
 .footerLinks .list-inline-item{
 	margin:0;
 }
 .footerLinks li a{
	font-size: 12px !important;	
	color:#fff;
	text-decoration: none;
	padding: 0;
 }
 .copyRight{
 	margin-top:45px; 
 	font-size: 12px;
 	color:#fff;
 	text-decoration: none;
 }
 .copyRight:hover{
 	color:#11990a;
 	text-decoration: none;
 }
.boxHeight{
	width: 100%;
	height: 37px;
}
.socialIcon a:hover{
	opacity: 0.7;
	color: #129a0b;
}
.footerLinks li a:hover{
 	 color: #129a0b;
}
.socialIcon a{
 	padding: 5px;    
    width: 40px;
    text-align: center;
    text-decoration: none;
    margin: 5px 2px;
} 
 .facebook{ 	
 	background: #3B5998;
    color: white;
 }
 .twitter {
  background: #55ACEE;
  color: white;
}
.linkedin {
  background: #007bb5;
  color: white;
}
.youtube {
  background: #bb0000;
  color: white;
}
.instagram {
  background: #125688;
  color: white;
}
/* footer */
.line {
    clear: left;
}
hr.break {
    border: 0;
    border-top: 1px solid #e5e5e5;
    border-color: rgba(0, 38, 51, 0.80);
    display: block;
    margin: 40px 0;
}

.navHeading{
	font-weight:bold;text-decoration: underline;color: #615f5f;
}
.jobBox{
	border:solid 1px #CCC;
	width: 100%;
	box-sizing: border-box;
	min-height: 10px;

}
.jobTitle{
	background: #e2e6e6;
	line-height: 1;
	padding: 10px;
}
.jobTitle p{	
	font-weight: bold;
	line-height: 1;
	margin-bottom: 10px;
}
p.bold{
	font-weight: bold;
	line-height: 1;
	margin-bottom: 10px;
}
p.bold span{
	font-weight: 400;
}
.jobTitle p span{
	font-weight: 400;
}
.jobDiscription{
	width: 100%;
	padding: 15px;
}
.jobFoooter{
	width: 100%;
	padding: 5px;
	border-top:#ccc solid 1px;
}
a.applyNow{background: #11990a; color: #fff; padding:5px 10px; display: block;}
a:hover .applyNow{color: #fff;}
a.readMore{color:#069; text-decoration:none}
.font-20{font-size: 20px;}



#nav-icon{
  width: 35px;
  height: 35px;
  position: relative; 
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon span{
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #11990a;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon span:nth-child(1) {
  top: 0px;
}

#nav-icon span:nth-child(2) {
  top: 10px;
}

#nav-icon span:nth-child(3) {
  top: 20px;
}

#nav-icon.open span:nth-child(1) {
   top: 18px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#nav-icon.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

#nav-icon.open span:nth-child(3) {
  top: 18px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.attention h6{
		font-weight: bold; 
		line-height: 1.1;
}
.attention p{
	   font-size: 1.0rem;
		line-height: 1.2;
}




@media (min-width: 576px) { 
	.navbar-brand img {
	    margin: 0 auto;
	    max-width:80px;
	    width: 100%;
	}
	.navbar-light .navbar-toggler {
	    color: rgba(0,0,0,.5);
	    border-color: transparent!important;
	    border:0;
	} 
	.navHeading{
		padding-left: 23px;
	}
}
@media (min-width: 768px) { 
	.navbar-brand img {
	    margin: 0 auto;
	    max-width: 100px;
	    width: 100%;
	}
	.navbar-light .navbar-toggler {
	    color: rgba(0,0,0,.5);
	    border-color: transparent!important;
	    border:0;
	}
	.navHeading{
		padding-left: 23px;
	}
}
@media (min-width: 992px) {
	.navbar-brand img {
	    margin: 0 auto;
	    max-width: 120px;
	    width: 100%;
	}
	.navbar-nav li:hover>.dropdown-menu {
	  display: block;
	}
	.navbar-custom .navbar-nav .nav-link {
	    color: #000;	         
	}
	.navbar-light .navbar-nav .nav-link:hover {
	    background: #49BF4C none repeat scroll 0 0;
    	color: #fff;    	      
	}
	.service{
	     min-width: 580px; 
	     padding-left: 8px
    } 
    .dropdown-menu a{
       font-size: 0.80rem;
       color: #272829;
       background-color: transparent;
     }
     .dropdown-menu a:hover {
       font-size: 0.85rem;
       color: #272829;
       background-color: transparent;
     }
	 .w-170{
	 	float:left; width: 170px;
	 }
	 .w-200{
	 	float:left; width: 200px;
	 } 
}
@media (min-width: 1200px) {
 	.navbar-brand img {
	    margin: 0 auto;
	    max-width: 180px;
	    width: 100%;
	}
	.dropdown-toggle::after { border: none; }
	.navbar-nav li:hover>.dropdown-menu {
	  display: block;
	}
	.navbar-custom .navbar-nav .nav-link {
	    color: #000;	         
	}
	.navbar-light .navbar-nav .nav-link:hover {
	    background: #49BF4C none repeat scroll 0 0;
    	color: #fff;    	      
	}
	.service{
	     min-width: 580px; 
	     padding-left: 8px
    }         
     .dropdown-menu a{
       font-size: 0.80rem;
       color: #272829;
       background-color: transparent;
     }
     .dropdown-menu a:hover {
       font-size: 0.85rem;
       color: #272829;
       background-color: transparent;
     }
     .w-170{
     	float:left; width: 170px;
     }
     .w-200{
     	float:left; width: 200px;
     }
     ul.navbar-nav li:last-child{
     	margin-left: 80px;     	
     }       
	
}

@media (min-width: 992px){
	.navbar-expand-lg .navbar-nav .dropdown-menu {
	    position: absolute;
	    background: #edeceb;
	    z-index: 1000;
	    top:97.5%;
		border: 0px;
		border-radius: 0px;
	    
	}
	.service .dropdown-item{
     	padding-left: 0;
     }
}

.padding {
    padding: 1.25rem!important;
}
.background-dark, .primary-color-dark .background-primary {
    background-color: #000 !important;
}
.size-1140 .line {
    max-width: 71.25rem;
}
