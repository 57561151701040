.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

pre{
  font-size: 16px;
  border: 2px solid grey;
  width: 100%;
  border-left: 12px solid green;
  border-radius: 5px;
  padding: 14px;
  font-family: Arial, Helvetica, sans-serif;
  
  /* Fixed line height */
  line-height: 24px;
  
  /* Use linear-gradient for background image */
  background-image: linear-gradient(180deg, #eee 50%, #fff 50%);
  
  /* Size background so that the height is 2x line-height */
  background-size: 100% 48px;
  
  /* Offset the background along the y-axis by top padding */
  background-position: 0 14px;

}

.rightdiv1{
  color:red;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.linkstyle{
  font-size: 16px;
}

.paddingleft{
  padding-left: 10px;
}

.head4{
  font-size: 20px; background: #006cb5; color: #fff; margin-top: 10px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.background{
    background: #bec1c1;
    border-top:solid 15px #8b8d8d;
}
.head1{
  font-size:18px; font-weight:bold; color:#4D4D4D; font-weight:bold;
}

.clearboth{
  clear:both;
}

.head2{
  font-size: 16px;background-color: #fff; padding: 10px; border: solid #e5e5e5 1px;
}

.head3{
  background-color: #ffffb6;
}

.App-link {
  color: #61dafb;
}

li{
  margin-left: 20px;
}

.marginzero {
    margin-left: 20px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
