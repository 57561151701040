.zeropadding{
	padding: 0px;
}

.navbar {
    border-radius: 0px;
}

.leftblue{
	border: solid 4px;
	border-color: #a7d5f2;
	font-size: 14px;
	margin-top: 10px;
	padding-right: 10px;
	background: #fff;
}

.img-circle {
    border-radius: 50%;
}

.linkstyle{
	text-decoration: none;
	color: #494a4a;

}

.rounded{
	background: #fff;
}

.head8{
	color:#000;
	font-size: 18px;
	font-weight: bold;
}

.head1{
	border: solid 4px;
    border-color: #a7d5f2;
    font-size: 18px;
    margin-top: 10px;
    background: #006cb5;
    color: #fff;
}

.head5{
	font-size:22px; font-weight:bold; font-style:italic; margin-top:20px;
}

.head6{
	font-size:18px; font-weight:bold;
}

.head7{
	font-size:22px; font-weight:bold; font-style:italic; margin-top:20px;
}

.thumbnail{
	background: #fff;
	margin-top: 10px;
	padding-left: 10px;
}

.aboutus{
	background: #fff;
	margin-top: 10px;
}

.enquire{
	background: #bce0f9;
	margin-top: 10px;
}

.typetext{
	border: solid 2px;
	border-color: #cfd0d1;
	font-size: 14px;
	font-weight: bold;
	margin-top: 10px;
	color:#000;
	text-align: left;
	padding-left: 10px;
}

.box2{
	border: solid 1px;
	border-color: #dadad6;
	font-size: 20px;
	margin-top: 10px;
	text-align: center;
}

.heading1{
	border: solid 4px;
	font-size: 24px;
    padding: 10px;
    background: #006cb5;
    margin-top: 10px;
    color: #fff;
    text-align: center;
}

.rightdiv1{
	color:#4a4a4b;
}

.heading2{
	border: solid 2px;
    border-color: #f4a7a8;
    padding: 10px;
    background: #f6dede;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
}

.content{
	border: solid 4px;
    border-color: #cbe6f8;
    padding: 10px;
    background: #fff;
    font-size: 16px;
}

.div1{
	font-size: 18px;
	background: #167ac6;
	color: #fff;
	margin-top: 10px;
}

.box1{
	border: solid 1px;
	border-color: #167ac6;
	background: #fff;
	padding:5px;
}


.left{

}